import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Grid';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from './theme';
import { Header } from './components/Header';
import { Main } from './components/Main';
import { Biography } from './components/Biography';
import { Portfolio } from './components/Portfolio';
import { Contact } from './components/Contact';
import { Project } from './components/Project';
import { Footer } from './components/common/Footer';
import { createBrowserRouter, RouterProvider, Outlet, useLocation } from 'react-router-dom';
import mainBackground from './assets/images/main/background.png';
import biographyBackground from './assets/images/biography/background.png';
import portfolioBackground from './assets/images/portfolio/background.png';
import contactBackground from './assets/images/contact/background.png';
import projectBackground from './assets/images/projects/background.png';
import { BasicDivider } from './components/common/BasicDivider';
import CursorCircle from './components/common/CursorCircle';

const App = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        { path: '/', element: <Main /> },
        { path: '/biography', element: <Biography /> },
        {
          path: '/portfolio',
          children: [
            {
              index: true,
              element: <Portfolio />,
            },
            {
              path: ':projectId',
              element: <Project />,
            }
          ]
        },
        { path: '/contact', element: <Contact /> },
      ]
    }
  ]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}

const Layout = () => {
  const location = useLocation();
  const [locationBackground, setLocationBackground] = useState(mainBackground);

  useEffect(() => {
    if (location.pathname === '/biography') {
      setLocationBackground(biographyBackground);
    } else if (location.pathname === '/contact') {
      setLocationBackground(contactBackground);
    } else if (location.pathname === '/portfolio') {
      setLocationBackground(portfolioBackground);
    } else if (location.pathname.includes('/portfolio/')) {
      setLocationBackground(projectBackground);
    } else {
      setLocationBackground(mainBackground);
    }
  }, [location])

  return (
    <Box
      sx={{
        position: 'relative', minHeight: '100vh',
        backgroundImage: `url(${locationBackground})`,
        backgroundSize: 'cover',
      }}
    >
      <CursorCircle />
      <Grid
        container
        direction='column'
        rowSpacing={3}
        px={{
          xs: 0,
          sm: 2.5,
          md: 5,
          lg: 7.5,
          xl: 7.5
        }}
      >
        <Grid item>
          <Header location={location.pathname} />
        </Grid>
        <BasicDivider />
        <Grid item>
          <Outlet />
        </Grid>
        <BasicDivider />
        <Grid item>
          <Footer />
        </Grid>
      </Grid>
    </Box >
  );
}

export default App;
