import { Box } from '@mui/material';
import star7 from "../../assets/images/doodles/7star.svg";

export const HorizontalIconDivider = ({ right = false, left = false, width = '60%' }) => {
    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent={right ? left ? "center" : "flex-start" : "flex-end"}
        >
            <Box
                width={`calc(${width} + 15px)`}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent={right ? left ? "center" : "flex-start" : "flex-end"}
            >
                {left &&
                    <img
                        width="50px"
                        src={star7}
                        style={{ paddingRight: 15 }}
                    />
                }
                <Box
                    sx={{
                        width: '100%',
                        borderBottomStyle: "solid",
                        borderBottomWidth: 1.5,
                        borderBottomColor: "#231E49",
                    }}
                />
                {
                    right &&
                    <img
                        width="50px"
                        src={star7}
                        style={{ paddingLeft: 15 }}
                    />
                }
            </Box >
        </Box>
    )
}