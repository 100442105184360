import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    IconButton,
    Typography,
    useMediaQuery,
    CardActionArea,
    Box,
    Grid,
    Modal,
    Backdrop
} from '@mui/material';
import { MenuIcon } from './icons/MenuIcon'
import { useTheme } from '@mui/material/styles';
import star4 from '../assets/images/doodles/4star.svg';
import logo from '../assets/images/logo.png';
import { MenuCloseIcon } from './icons/MenuCloseIcon';


const MenuItem = ({ to, children, currentLocation, onClick = null }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseOver = () => setIsHovered(true);
    const handleMouseOut = () => setIsHovered(false);

    const boxStyle = currentLocation.includes(to) || isHovered
        ? {
            display: 'inline-block',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 200,
            borderColor: '#231E49',
            px: 2.5
        }
        : { px: 2.5 };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
            }}
        >
            <RouterLink to={to} style={{ textDecoration: 'none', color: 'inherit' }}>
                <CardActionArea
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    onClick={onClick}
                    sx={{
                        borderRadius: 200,
                    }}
                >
                    <Box sx={boxStyle}>
                        <Typography textAlign='center' color='#231E49' sx={{ fontSize: 20, fontFamily: 'CampaignLight' }}>
                            {children}
                        </Typography>
                    </Box >
                </CardActionArea>
            </RouterLink>
        </Box>
    )
}

const HeaderIcons = () => {

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                alignContent: 'center',
                height: '100%'
            }}
        >
            <Grid
                container
                direction='row'
                columnSpacing={{
                    xs: 1,
                    md: 2,
                    lg: 2.5
                }}
                sx={{
                    width: {
                        xs: '80%',
                        sm: '60%',
                        md: '50%',
                        lg: '40%',
                        xl: '30%',
                    },
                }}
            >
                {
                    [...Array(3).keys()].map((i) => (
                        <Grid item xs key={i} display='flex'>
                            <Box
                                component="img"
                                src={star4}
                                alt=""
                                color='#231E49'
                                loading='lazy'
                                width='100%'
                            />
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    )
}

const HeaderLogo = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                height: '100%'
            }}
        >
            <Box
                sx={{
                    width: {
                        xs: '70%',
                        sm: '50%',
                        md: '35%',
                        lg: '25%',
                        xl: '20%',
                    },
                }}
            >
                <RouterLink
                    to="/"
                    style={{
                        textDecoration: 'none',
                        color: 'inherit',
                    }}
                >
                    <Box
                        loading="eager"
                        component="img"
                        src={logo}
                        alt="ZEL"
                        sx={{
                            width: '100%',
                        }}
                    />
                </RouterLink>
            </Box>
        </Box>
    );
}

const HeaderMenuLg = (props) => {
    return (
        <Grid container height='100%' {...props}>
            <Grid xs item alignItems='center' display='flex'>
                <MenuItem to='/biography' currentLocation={props.location} onClick={props.onClick}>biografía</MenuItem>
            </Grid>
            <Grid xs item alignItems='center' display='flex'>
                <MenuItem to='/portfolio' currentLocation={props.location} onClick={props.onClick}>portafolio</MenuItem>
            </Grid>
            <Grid xs item alignItems='center' display='flex'>
                <MenuItem to='/contact' currentLocation={props.location} onClick={props.onClick}>contacto</MenuItem>
            </Grid>
        </Grid>
    )
}

const HeaderMenuSm = ({ location, menuIsOpened, onClick }) => {

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    height: '100%'
                }}
            >
                <IconButton onClick={onClick}>
                    {!menuIsOpened
                        ? <MenuIcon style={{ fontSize: 40 }} />
                        : <MenuCloseIcon style={{ fontSize: 40 }} />
                    }
                </IconButton>
            </Box>
            <Modal
                open={menuIsOpened}
                onClose={onClick}
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        style: {
                            backgroundColor: 'transparent',
                        },
                    },
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 85,
                        width: '100vw',
                        bgcolor: 'white',
                        borderBottomLeftRadius: 20,
                        borderBottomRightRadius: 20,
                        py: 5
                    }}
                >
                    <HeaderMenuLg location={location} direction='column' spacing={5} onClick={onClick} />
                </Box>
            </Modal>
        </>

    )
}


export const Header = ({ location }) => {
    const [menuIsOpened, setMenuIsOpened] = useState(false);
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.between('xs', 'lg'));

    const onClick = () => setMenuIsOpened(!menuIsOpened);

    return (
        <Grid
            container
            direction='row'
            sx={{
                px: {
                    xs: 2.5,
                    md: 5,
                    lg: 7.5
                },
            }}
            py={2}
            px={7.5}
            bgcolor={menuIsOpened ? 'white' : null}
        >
            <Grid xs item>
                <HeaderIcons />
            </Grid>
            <Grid xs item>
                <HeaderLogo />
            </Grid>
            <Grid xs item>
                {isSm
                    ? <HeaderMenuSm location={location} menuIsOpened={menuIsOpened} onClick={onClick} />
                    : <HeaderMenuLg location={location} />
                }
            </Grid>
        </Grid>
    );
}