import { Box } from '@mui/material';
import star7 from "../../assets/images/doodles/7star.svg";

export const VerticalIconDivider = ({ position }) => {
    return (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                gap: 3
            }}
        >
            <img
                width="40px"
                src={star7}
            />
            <Box
                sx={{
                    height: "100%",
                    borderLeftStyle: "solid",
                    borderLeftWidth: 1.5,
                    borderLeftColor: "#231E49",
                }}
            />
            <img
                width="40px"
                src={star7}
            />
        </Box >
    )
}