import { Link } from 'react-router-dom';
import { HorizontalIconDivider } from './common/HorizontalIconDivider';
import {
    Box, Grid, Divider, Typography, useMediaQuery
} from '@mui/material';
import mainPhoto from '../assets/images/main/main_photo.png';
import { Doodle } from './common/Doodle';
import { useTheme } from '@mui/material/styles';

const Greeting = () => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.between('xs', 'lg'));

    return (
        <Grid
            container
            direction='column'
            justifyContent='space-between'
            height='100%'
        >
            <Grid xs item>
                <Doodle
                    absolute={false}
                    doodleName='bear'
                    alt=''
                    top={30}
                    left={10}
                    xs='4vh'
                    sm='8vh'
                    md='10vh'
                    lg='15vh'
                    xl='8vh'
                />
            </Grid>
            <Grid xs item>
                <Divider
                    sx={{
                        '&::before, &::after': {
                            borderWidth: 2,
                            borderBlockColor: '#231E49',
                        }
                    }}
                >
                    <Typography
                        color='#231E49'
                        sx={{
                            fontFamily: 'DreamAvenue',
                            userSelect: 'none',
                            fontSize: {
                                xs: '20vw',
                                sm: '20vw',
                                md: '17.5.vw',
                                lg: '15vw',
                                xl: '15vw',
                            }
                        }}>
                        hey!
                    </Typography>
                </Divider>
            </Grid>
            <Grid xs item>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: {
                            xs: '-3vh',
                            sm: '-3vh',
                            md: '-5vh',
                            lg: '-5vh',
                            xl: '-8vh',
                        },
                        paddingLeft: {
                            xs: '3vw',
                            sm: '3vw',
                            md: '3vw',
                            lg: '2.5vw',
                            xl: '2.5vw'
                        },
                    }}
                >
                    <Doodle
                        absolute={false}
                        doodleName='croissant'
                        alt=''
                        top={0}
                        right={5}
                        xs='3vh'
                        sm='5vh'
                        md='10vh'
                        lg='15vh'
                        xl='8vh'
                    />
                    <Typography
                        textAlign='left'
                        color='#231E49'
                        sx={{
                            fontSize: {
                                xs: '8vw',
                                md: '6.5vw',
                                lg: '7vw',
                                xl: '5vw'
                            },
                            fontFamily: 'DreamAvenue',
                            userSelect: 'none',
                        }}
                    >
                        encantada
                    </Typography>
                    <Typography
                        textAlign='right'
                        noWrap={true}
                        color='#231E49'
                        sx={{
                            position: 'absolute',
                            marginTop: {
                                xs: '4.5vh',
                                sm: '8vh',
                                md: '10vh',
                                lg: '14vh',
                                xl: '8vh',
                            },
                            left: {
                                xs: '12vw',
                                sm: '16vw',
                                md: '20vw',
                                lg: '16vw',
                                xl: '14vw',
                            },
                            fontSize: {
                                xs: '8vw',
                                sm: '8vw',
                                md: '7vw',
                                lg: '7vw',
                                xl: '5vw'
                            },
                            zIndex: 10,
                            fontFamily: 'DreamAvenue',
                            userSelect: 'none',
                        }}
                    >
                        de conocerte
                    </Typography>
                </Box>
            </Grid>
            <Grid xs item>
                <Doodle
                    absolute={false}
                    doodleName='bag'
                    alt=''
                    bottom={0}
                    left={isSm ? 10 : 0}
                    xs='5vh'
                    sm='7vh'
                    md='12vh'
                    lg='18vh'
                    xl='10vh'
                />
            </Grid>
        </Grid >
    )
}

const Details = () => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.between('xs', 'xl'));

    const DetailButtons = ({ children, to }) => {
        return (
            <Link to={to} target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: {
                            xs: 'center',
                            sm: 'center',
                            md: 'center',
                            lg: 'center',
                            xl: 'flex-end'
                        },
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'inline-block',
                            borderStyle: 'solid',
                            borderColor: '#231E49',
                            borderWidth: 1.5,
                            borderRadius: 200,
                        }}
                    >
                        <Typography
                            color='#231E49'
                            noWrap={true}
                            sx={{
                                fontFamily: 'CampaignLight',
                                px: 1,
                                fontSize: {
                                    xs: '1.5rem',
                                    sm: '1.5rem',
                                    md: '1.5rem',
                                    lg: '1.75rem',
                                    xl: '1.4rem',
                                },
                            }}
                        >
                            {children}
                        </Typography>
                    </Box>
                </Box>
            </Link>
        )
    }

    return (
        <Grid
            container
            direction='column'
            justifyContent='space-between'
            height='100%'
        >
            <Grid xs item>
                {!isSm &&
                    <Grid container direction='row' justifyContent='center' mb={20}>
                        <Grid item xs>
                            <Doodle
                                absolute={false}
                                doodleName='heart'
                                alt=''
                                top={0}
                                right={20}
                                xl='2vh'
                            />
                        </Grid>
                        <Grid item xs='auto'>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    borderWidth: 1.5,
                                    borderStyle: 'solid',
                                    borderRadius: '50%',
                                    borderColor: '#231E49'
                                }}
                            >
                                <Typography
                                    color='#231E49'
                                    noWrap={true}
                                    sx={{
                                        fontFamily: 'CampaignLight',
                                        fontSize: '1.1rem',
                                        userSelect: 'none',
                                        py: 1.75,
                                        px: 1
                                    }}
                                >
                                    ¡te dejo cotillear!
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs>
                            <Doodle
                                absolute={false}
                                doodleName='arrow'
                                alt=''
                                top={-10}
                                left={10}
                                xl='2vh'
                            />
                        </Grid>
                        <Grid item xs>
                            <Doodle
                                absolute={false}
                                doodleName='drink'
                                alt=''
                                top={60}
                                left={15}
                                xl='8vh'
                            />
                        </Grid>
                    </Grid>
                }
            </Grid>
            <HorizontalIconDivider right={true} width='50%' />
            <Grid xs item>
                <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    height='100%'
                    sx={{
                        px: 4,
                        py: {
                            xs: 5,
                            sm: 5,
                            md: 5,
                            lg: 5,
                            xl: 5
                        }
                    }}
                >
                    <Typography
                        color='#231E49'
                        sx={{
                            fontFamily: 'CampaignLight',
                            fontSize: {
                                xs: '1.25rem',
                                sm: '1.25rem',
                                md: '1.5rem',
                                lg: '1.5rem',
                                xl: '1.15rem',
                            },
                            lineHeight: 1.25,
                        }}
                    >
                        Soy Celia Veiga, nací en Lugo en 2002 y actualmente soy <Box component='span' fontWeight='bold'>ilustradora y animadora 2D</Box>. Mi propósito en el mundo creativo es, sin duda, crear historias inspiradoras. Principalmente me gusta que mi público sea infantil y juvenil. Sin embargo,  también disfruto del reto de adaptarme a nuevos proyectos en los que pueda exprimir mis capacidades creativas.
                    </Typography>
                </Box>
            </Grid>
            <HorizontalIconDivider left={true} width='50%' />
            <Grid xs item>
                <Grid
                    container
                    height='100%'
                    direction='row'
                >
                    <Grid item xs>
                        <Doodle
                            absolute={false}
                            doodleName='orange'
                            alt=''
                            top={35}
                            left={25}
                            xs='8vh'
                            sm='12vh'
                            md='10vh'
                            lg='15vh'
                            xl='10vh'
                        />
                    </Grid>
                    <Grid
                        item
                        xs
                        pt={{
                            xs: 5,
                            md: 3,
                            lg: 5,
                            xl: 0
                        }}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Grid
                            container
                            rowSpacing={2}
                            direction='column'
                        >
                            <Grid xs item>
                                <DetailButtons
                                    to={"https://drive.google.com/file/d/1GqHzX-Me3932dhuWunT6hvHdaDfBlTzH/view?usp=sharing"}
                                >
                                    portafolio
                                </DetailButtons>
                            </Grid>
                            <Grid xs item>
                                <DetailButtons
                                    to={"https://drive.google.com/file/d/1OKnoKGwoRIpHlAJ_LwwONv7Oal2l3coW/view?usp=sharing"}
                                >
                                    curriculum
                                </DetailButtons>
                            </Grid>
                            <Grid xs item>
                                <DetailButtons
                                    to={"https://youtu.be/SA5br8XRl8M"}
                                >
                                    reel animado
                                </DetailButtons>
                            </Grid>
                        </Grid>
                    </Grid>
                    {isSm &&
                        <Grid item xs
                        />
                    }
                </Grid>
            </Grid>
        </Grid >
    )
}

export const MainPhoto = () => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.between('xs', 'xl'));

    return (
        <Box
            pt={{
                xs: '10%',
                sm: '10%',
                md: '15%',
                lg: '20%',
                xl: '15%'
            }}
            pr={{
                xs: 3,
                sm: 3,
                md: 3,
                lg: 0,
                xl: 0
            }}
        >
            {isSm && <Doodle
                absolute={false}
                doodleName='drink'
                alt=''
                top={0}
                right={0}
                xs='4vh'
                sm='6vh'
                md='10vh'
                lg='12vh'
            />
            }
            <Box
                component='img'
                src={mainPhoto}
                alt='Main Photo'
                sx={{
                    borderTopLeftRadius: 500,
                    borderTopRightRadius: 500,
                    borderStyle: 'solid',
                    borderWidth: 1.5,
                    borderColor: '#231E49',
                    height: '100%',
                    width: '100%'
                }}
            />
        </Box>
    )
}

export const Main = () => {
    return (
        <Grid
            container
            direction='row'
            rowSpacing={5}
            sx={{
                pb: {
                    xs: 5,
                    sm: 5,
                    md: 10,
                    lg: 15,
                    xl: 20
                },
            }}
        >
            <Grid item xs={6} sm={6} md={6} lg={6} xl>
                <Greeting />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                <MainPhoto />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg xl >
                <Details />
            </Grid>
        </Grid>
    )
}