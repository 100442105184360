import DreamAvenue from './fonts/Dream_Avenue.woff';
import CampaignBlack from './fonts/Campaign_Black.woff';
import CampaignLight from './fonts/Campaign_Light.woff';
import CampaignLightItalic from './fonts/Campaign_Light_Italic.woff';
import CampaignMedium from './fonts/Campaign_Medium.woff';
import CampaignRegular from './fonts/Campaign_Regular.woff';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        fontFamily: 'CampaignLight, DreamAvenue, CampaignBlack, CampaignLightItalic, CampaignMedium, CampaignRegular',
    },
    palette: {
        primary: {
            main: "#231E49",
        },
        secondary: {
            main: "#A494C3",
        },
        pink: {
            main: "#F9D2DF",
        },
        yellow: {
            main: "#F1EBBD",
        },
        blue: {
            main: "#B4CFED",
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: 'CampaignLight';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: local('CampaignLight'), url(${CampaignLight}) format('woff');
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
                @font-face {
                    font-family: 'DreamAvenue';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: local('DreamAvenue'), local('DreamAvenue-Regular'), url(${DreamAvenue}) format('woff');
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
                @font-face {
                    font-family: 'CampaignBlack';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: local('CampaignBlack'), url(${CampaignBlack}) format('woff');
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
                @font-face {
                    font-family: 'CampaignLightItalic';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: local('CampaignLightItalic'), url(${CampaignLightItalic}) format('woff');
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
                @font-face {
                    font-family: 'CampaignMedium';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: local('CampaignMedium'), url(${CampaignMedium}) format('woff');
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
                @font-face {
                    font-family: 'CampaignRegular';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: local('CampaignRegular'), url(${CampaignRegular}) format('woff');
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
            `,
        },
    },
});