import mainPhoto from '../assets/images/biography/main.png';
import young from '../assets/images/biography/young.png';
import interaction from '../assets/images/biography/interaction.png';
import { VerticalIconDivider } from './common/VerticalIconDivider';
import { Grid, Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Doodle } from './common/Doodle';
import { HorizontalIconDivider } from './common/HorizontalIconDivider';
import cross from '../assets/images/doodles/cross.svg';
import flower from '../assets/images/doodles/flower.svg';

export const BiographyText = () => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.between('xs', 'lg'));

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                alignContent: 'center',
            }}
        >
            <Grid
                container
                direction='column'
                justifyContent='center'
                alignContent='center'
                rowSpacing={0.5}
            >
                <Grid item xs justifyContent='center' display='flex'>
                    <Box
                        component="img"
                        src={cross}
                        srcSet={cross}
                        loading='eager'
                        alt=''
                        color='#231E49'
                        sx={{
                            width: {
                                xs: '6vw',
                                sm: '4vw',
                                md: '4vw',
                                lg: '1.5vw',
                                xl: '1.5vw'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs>
                    <Box
                        sx={{
                            bgcolor: '#aea0c8',
                            borderRadius: {
                                xs: 15,
                                sm: 15,
                                md: 12.5,
                                lg: 10,
                                xl: 10,
                            },
                            ml: {
                                xs: 0,
                                sm: 0,
                                md: 0,
                                lg: 6,
                                xl: 6
                            },
                            mr: {
                                xs: 0,
                                sm: 0,
                                md: 0,
                                lg: -3,
                                xl: -3
                            }
                        }}
                    >
                        <Grid
                            container
                            direction='row'
                            columnSpacing={{
                                xs: 1,
                                sm: 2,
                                md: 2,
                                lg: 2,
                                xl: 2
                            }}
                            rowGap={3}
                            sx={{
                                px: {
                                    xs: 5,
                                    sm: 5,
                                    md: 5,
                                    lg: 3,
                                    xl: 3
                                },
                                py: 2.5,
                            }}
                        >
                            <Grid item xs={12} sm={12} md={12} lg={8} xl={8.5}>
                                <Typography
                                    color='white'
                                    sx={{
                                        fontFamily: 'CampaignLightItalic',
                                        fontSize: 14.5,
                                        lineHeight: 1.25,
                                        mr: {
                                            lg: 5,
                                            xl: 5
                                        }
                                    }}
                                >
                                    Mi pasión por contar historias con dibujos viene desde muy pequeña. Siempre me han encantado los dibujos animados y he tenido muy buena relación con el arte durante toda mi infancia.
                                    <br />
                                    <br />
                                    Ya en el instituto empecé a aprender arte en una academia de dibujo y a hacer pruebas con una cámara de fotos, despertando poco a poco mi pasión por el ámbito creativo.
                                    <br />
                                    <br />
                                    Cuando ya tocaba salir del instituto decidí que, por muy complicado que fuera, iba a dedicar mi vida al arte. Sin embargo, no sabía que caminos existían, así que tras mucho estudio pude entrar en 2020 al Grado en Diseño y Tecnologías Creativas.
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={4} xl={3.5}>
                                <Box
                                    loading="eager"
                                    component="img"
                                    src={young}
                                    alt="Young Celia"
                                    height='auto'
                                    width='100%'
                                    sx={{
                                        borderRadius: 5
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                                <Box
                                    loading="eager"
                                    component="img"
                                    src={interaction}
                                    alt="Interaction"
                                    height='auto'
                                    width='100%'
                                    sx={{
                                        borderRadius: 5
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                                <Typography
                                    textAlign={isSm ? 'left' : 'right'}
                                    color='white'
                                    sx={{
                                        fontFamily: 'CampaignLightItalic',
                                        fontSize: 14.5,
                                        lineHeight: 1.25,
                                    }}
                                >
                                    En esta carrera pude explorar las diferentes ramas que me ofrecía el arte digital. Tras muchos proyectos, programas, técnicas aprendidas, exposiciones... me dí cuenta de que los caminos que se acercaban más a mi propósito creativo de crear y contar historias eran la ilustración y la animación.
                                    <br />
                                    <br />
                                    Creo que lo que se hace con pasión tiene mejores resultados. Por ello siempre he tratado de trabajar en equipos en los que hay amor por un proyecto.
                                    <br />
                                    <br />
                                    Durante la carrera  he podido presentar dos demo de videojuego que he dirigido en 2023 (Equanimity y Clara y Yema) y una cortinilla animada en 2024 (Paella the animation!).
                                    <br />
                                    <br />
                                    Ahora que estoy a punto de cerrar este ciclo, siento mucha ilusión por poder participar en proyectos que me hagan sentir tan realizada como los elaborados durante mi formación. Por ello, si te gusta mi forma de ver las cosas y de trabajar, estoy abierta a escuchar cualquier propuesta de proyecto creativo.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid >
                <Grid item xs justifyContent='center' display='flex'>
                    <Box
                        component="img"
                        src={flower}
                        srcSet={flower}
                        loading='lazy'
                        alt=''
                        color='#231E49'
                        sx={{
                            width: {
                                xs: '18vw',
                                sm: '10vw',
                                md: '10vw',
                                lg: '5vw',
                                xl: '5vw'
                            }
                        }}
                    />
                </Grid>
            </Grid >
        </Box >
    )
}


const BiographyPhoto = () => {

    return (
        <Box
            height='100%'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignContent='center'
            alignItems='center'
        >
            < Grid
                container
                direction='column'
                justifyContent='center'
                height='100%'
                gap={2}
            >
                <Grid xs='auto' item>
                    <Box
                        sx={{
                            position: 'relative',
                        }}
                    >
                        <Doodle
                            doodleName='pancake'
                            alt=''
                            top={0}
                            left={18}
                            xs='18vw'
                            sm='14vw'
                            md='14vw'
                            lg='8vw'
                            xl='8vw'
                        />
                        <Doodle
                            doodleName='6star'
                            alt=''
                            top={-5}
                            left={35}
                            xs='4vw'
                            sm='4vw'
                            md='4vw'
                            lg='2vw'
                            xl='2vw'
                        />
                        <Doodle
                            doodleName='glasses'
                            alt=''
                            top={0}
                            right={5}
                            xs='15vw'
                            sm='10vw'
                            md='10vw'
                            lg='5vw'
                            xl='5vw'
                        />
                        <Doodle
                            doodleName='sun'
                            alt=''
                            top={10}
                            right={24}
                            xs='15vw'
                            sm='10vw'
                            md='10vw'
                            lg='6vw'
                            xl='5vw'
                        />
                        <Doodle
                            doodleName='weights'
                            alt=''
                            top={40}
                            left={2.5}
                            xs='12.5vw'
                            sm='10vw'
                            md='10vw'
                            lg='4vw'
                            xl='4vw'
                        />
                        <Doodle
                            doodleName='cloud'
                            alt=''
                            bottom={20}
                            left={17.5}
                            xs='14vw'
                            sm='10vw'
                            md='10vw'
                            lg='6vw'
                            xl='6vw'
                        />
                        <Doodle
                            doodleName='cake'
                            alt=''
                            bottom={10}
                            right={5}
                            xs='12.5vw'
                            sm='10vw'
                            md='10vw'
                            lg='4vw'
                            xl='4vw'
                        />
                        <Box
                            loading="eager"
                            component="img"
                            src={mainPhoto}
                            sx={{
                                width: {
                                    xs: '60%',
                                    sm: '60%',
                                    md: '50%',
                                    lg: '65%',
                                    xl: '45%',
                                },
                                p: {
                                    xs: 1.5,
                                    sm: 1.5,
                                    md: 2,
                                    lg: 2,
                                    xl: 2,
                                },
                                borderWidth: {
                                    xs: 1,
                                    sm: 1,
                                    md: 1,
                                    lg: 1,
                                    xl: 1,
                                },
                                borderTopLeftRadius: 500,
                                borderTopRightRadius: 500,
                                borderStyle: 'solid',
                                borderColor: '#231E49',
                                display: 'block',
                                margin: '0 auto'
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs='auto'>
                    <Box
                        sx={{
                            position: 'relative',
                        }}
                    >
                        <Doodle
                            doodleName='bubbles'
                            alt=''
                            bottom={0}
                            left={2.5}
                            xs='10vw'
                            sm='6vw'
                            md='6vw'
                            lg='4vw'
                            xl='4vw'
                        />
                        <Box
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            height='100%'
                            sx={{
                                px: '5.5vw'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'inline-block',
                                    borderStyle: "solid",
                                    borderColor: '#231E49',
                                    borderWidth: 1,
                                    borderRadius: 200,
                                    p: 2.5
                                }}
                            >
                                <Typography
                                    variant='h6'
                                    textAlign='center'
                                    color='#231E49'
                                    sx={{
                                        fontFamily: 'CampaignLightItalic',
                                        fontSize: {
                                            xs: '1rem',
                                            sm: '1.5rem',
                                            md: '2rem',
                                            lg: '1.25rem',
                                            xl: '1.25rem'
                                        }
                                    }}
                                >
                                    ¡Buenas! Soy Celia Veiga. Nací en Lugo en 2002 y me dedico a la ilustración y la animación 2D para un público infantil y juvenil.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid >
        </Box >
    )
}

export const Biography = () => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.between('xs', 'lg'));

    return (
        <Grid
            container
            direction='row'
            rowSpacing={5}
            sx={{
                pb: {
                    xs: 10,
                    sm: 10,
                    md: 10,
                    lg: 10,
                    xl: 10
                }
            }}
        >
            <Grid item xs={12} sm={12} md={12} lg xl>
                <BiographyPhoto />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg='auto' xl='auto'>
                {!isSm
                    ? <VerticalIconDivider />
                    : <HorizontalIconDivider right={true} left={true} width='90%' />
                }
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg xl>
                <BiographyText />
            </Grid>
        </Grid>
    )
}