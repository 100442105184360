import { SvgIcon } from '@mui/material';

export const MenuCloseIcon = (props) => {
    const style = {
        fill: 'none',
        stroke: '#262451',
        strokeMiterlimit: 10,
        strokeWidth: '0.5px',
    };

    return (
        <SvgIcon {...props} viewBox="0 0 16.19 16.19">
            <g id="RESPOPNSIVE_MOVIL" data-name="RESPOPNSIVE MOVIL">
                <g id="home_page_con_menuç" data-name="home page con menuç">
                    <path
                        d="M15.1,1.1l-3.96,5.51c-.64.89-.64,2.09,0,2.98l3.96,5.51-5.51-3.96c-.89-.64-2.09-.64-2.98,0l-5.51,3.96,3.96-5.51c.64-.89.64-2.09,0-2.98L1.1,1.1l5.51,3.96c.89.64,2.09.64,2.98,0l5.51-3.96Z"
                        style={style}
                    />
                </g>
            </g>
        </SvgIcon>
    );
};
