import Divider from '@mui/material/Divider';

export const BasicDivider = () => {

    return (
        <Divider flexItem
            sx={{
                borderWidth: 1,
                borderBlockColor: "#231E49"
            }}
        />
    )
}