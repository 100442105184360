
import { SvgIcon } from '@mui/material';

export const BehanceIcon = (props) => {
    const style = {
        fill: '#231e49',
        strokeWidth: '0px',
    };

    return (
        <SvgIcon {...props} viewBox="0 0 1223 768">
            <g id="behanceIcon" data-name="behanceIcon">
                <g id="contacto">
                    <g>
                        <path
                            d="M615.74,569.51c0,32.41-6.48,60.89-19.44,85.37-12.99,24.5-30.8,45.03-53.51,61.59-22.7,16.59-49,29.18-78.88,37.82-29.9,8.66-61.79,12.97-95.64,12.97H-2.4V0h410.64C434.9,0,459.04,5.77,480.66,17.3c21.61,11.54,39.98,26.47,55.1,44.84,15.13,18.37,26.84,39.29,35.13,62.69,8.28,23.42,12.42,47.01,12.42,70.78,0,37.45-9.36,72.59-28.08,105.36-18.75,32.78-46.12,57.09-82.14,72.94,43.95,12.96,78.7,36.21,104.29,69.7,25.56,33.51,38.37,75.48,38.37,125.9ZM146.73,127.53v193.44h198.84c25.21,0,46.64-8.66,64.3-25.95,17.63-17.28,26.47-41.06,26.47-71.31s-8.11-52.95-24.32-70.24c-16.2-17.29-36.19-25.93-59.97-25.93h-205.32ZM465.53,541.4c0-13.68-2.34-26.83-7.03-39.44-4.68-12.59-11-23.59-18.9-32.96-7.93-9.36-17.29-16.75-28.1-22.15-10.81-5.4-22.69-8.11-35.66-8.11h-229.1v202.07h221.54c13.68,0,26.48-2.52,38.36-7.57,11.89-5.03,22.15-12.06,30.8-21.07,8.64-9,15.48-19.63,20.53-31.88,5.03-12.23,7.56-25.2,7.56-38.9Z"
                            style={style}
                        />
                        <path
                            d="M938.75,767.25c-43.97,0-83.75-7.51-119.36-22.51-70.53-30.02-120.27-80.46-149.21-151.3-13.61-34.2-20.41-70.15-20.41-107.85s6.63-75.91,19.89-110.47c28.42-71.74,78.16-122.87,149.21-153.4,35.95-15.34,76.26-23.03,120.94-23.03s84.63,7.69,119.89,23.03c115.88,50.52,170.47,162.82,165.44,284.81-.36,8.37-1.24,15.37-2.61,20.94h-424.08c2.09,21.64,7.33,40.83,15.71,57.58,8.38,16.76,19.19,31.07,32.46,42.94,13.25,11.87,28.27,20.94,45.03,27.22,16.75,6.28,34.18,9.43,52.35,9.43,27.9,0,54.26-6.81,79.04-20.43,24.78-13.61,41.72-31.56,50.79-53.92l120.41,33.51c-20.24,41.89-52.52,76.27-96.85,103.14-44.33,26.88-97.21,40.31-158.64,40.31ZM1081.18,436.39c-3.51-41.18-18.69-74.17-45.56-98.95-26.88-24.77-59.52-37.18-97.9-37.18-18.85,0-36.48,3.32-52.88,9.95-16.41,6.64-30.89,15.88-43.45,27.75-12.56,11.88-22.87,26.17-30.89,42.94-8.03,16.74-12.74,35.26-14.13,55.48h284.81Z"
                            style={style}
                        />
                        <path
                            d="M754.15,36.43h367.15v91.1h-367.15V36.43Z"
                            style={style}
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}