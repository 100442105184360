import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';
import { Grid, Typography, Box, InputBase, ButtonBase, useMediaQuery } from '@mui/material';
import { SocialMediaButtons } from './common/SocialMediaButtons';
import { Doodle } from './common/Doodle';
import { useTheme } from '@emotion/react';

const EMAILJS_SERVICE_ID = 'service_b8mfc5e'
const EMAILJS_TEMPLATE_ID = 'template_op63vrp'
const EMAILJS_PUBLIC_KEY = '4gSompX17SoEV8jrr'

const LabelTextInput = ({ label, onChange, value }) => {
    return (
        <Box
            sx={{
                borderRadius: 200,
                borderStyle: 'solid',
                borderWidth: 1.5,
                borderColor: '#231E49'
            }}
        >
            <Grid container direction='row' justifyContent='flex-start'>
                <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>
                    <Typography
                        color='#231E49'
                        sx={{
                            fontFamily: 'CampaignLight',
                            textAlign: 'center',
                            py: 1.5
                        }}
                    >
                        {label}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <InputBase
                        fullWidth
                        color='#231E49'
                        onChange={onChange}
                        value={value}
                        sx={{
                            px: 2,
                            fontFamily: 'CampaignLight',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }} />
                </Grid>
            </Grid>
        </Box>
    )
}

const ContactForm = () => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [body, setBody] = useState('');
    const [mailError, setMailError] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);

    useEffect(() => {
        setMailError(!!mail.length && (!mail.includes('@') || !mail.includes('.')));
    }, [mail]);

    useEffect(() => {
        setDisabledButton(
            !name.length || !mail.length || !body.length || mailError
        )
    }, [name, mail, body, mailError]);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, { name, mail, body }, EMAILJS_PUBLIC_KEY)
            .then(
                () => {
                    console.log('SUCCESS!');
                    setName('');
                    setMail('');
                    setBody('');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <Box
            sx={{
                position: 'relative',
                height: '100%'
            }}
        >
            <Doodle
                doodleName='bow'
                alt=''
                top={isSm ? -10 : -20}
                left={isSm ? 7.5 : 10}
                xs='9vw'
                sm='7.5vw'
                md='7.5vw'
                lg='5vw'
                xl='5vw'
            />
            <Doodle
                doodleName='hearts'
                alt=''
                top={isSm ? 25 : -20}
                right={5}
                xs='6vw'
                sm='5vw'
                md='4vw'
                lg='4vw'
                xl='3.5vw'
            />
            <Doodle
                doodleName='bird'
                alt=''
                bottom={15}
                right={isSm ? 18 : 22.5}
                xs='10vw'
                sm='6vw'
                md='6vw'
                lg='4vw'
                xl='3.5vw'
            />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Grid
                    container
                    direction='column'
                    sx={{
                        width: {
                            xs: '80%',
                            sm: '70%',
                            md: '65%',
                            lg: '70%',
                            xl: '60%'
                        },
                    }}
                >
                    <Grid xs item pb={4}>
                        <LabelTextInput label='Nombre' onChange={(e) => setName(e.target.value)} value={name} />
                    </Grid>
                    <Grid xs item pb={mailError ? 1 : 4}>
                        <LabelTextInput label='Correo' onChange={(e) => setMail(e.target.value)} value={mail} />
                        {mailError &&
                            <Typography variant='error' color='red' sx={{ fontSize: 8, pl: 2, pt: 0, pb: 0 }}>
                                Por favor, introduce un correo válido
                            </Typography>
                        }
                    </Grid>
                    <Grid xs={8} item pb={4}>
                        <Box
                            sx={{
                                borderRadius: 5,
                                borderStyle: 'solid',
                                borderWidth: 1.5,
                                borderColor: '#231E49'
                            }}
                        >
                            <InputBase
                                fullWidth
                                multiline
                                rows={12}
                                placeholder='¡Cuenta, cuenta!'
                                value={body}
                                onChange={(e) => setBody(e.target.value)}
                                color='#231E49'
                                sx={{
                                    p: 2,
                                    fontFamily: 'CampaignLight',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                            </InputBase>
                        </Box>
                    </Grid>
                    <Grid xs item>
                        <Box
                            sx={{
                                width: '25%',
                                borderRadius: 200,
                                borderStyle: 'solid',
                                borderWidth: 1.5,
                                borderColor: disabledButton ? 'grey' : '#231E49',
                                '@media (max-width: 600px)': {
                                    width: '35%'
                                },
                            }}
                        >
                            <ButtonBase
                                onClick={sendEmail}
                                disabled={disabledButton}
                                sx={{
                                    width: '100%',
                                    borderRadius: 200,
                                    borderColor: '#231E49',
                                }}
                            >
                                <Typography
                                    color={disabledButton ? 'grey' : '#231E49'}
                                    sx={{
                                        fontSize: '1.5rem',
                                        fontFamily: 'CampaignLight',
                                        textAlign: 'center',
                                    }}
                                >
                                    enviar
                                </Typography>
                            </ButtonBase>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

const ContactText = () => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.between('xs', 'lg'));

    return (
        <Box
            sx={{
                position: 'relative',
                height: '100%'
            }}
        >
            <Doodle
                doodleName='flower2'
                alt=''
                top={isSm ? 80 : 35}
                left={isSm ? 85 : -10}
                xs='8vw'
                sm='6vw'
                md='5vw'
                lg='6vw'
                xl='5vw'
            />
            <Doodle
                doodleName='flower3'
                alt=''
                top={isSm ? -5 : -5}
                right={isSm ? 5 : -5}
                xs='10vw'
                sm='9vw'
                md='8vw'
                lg='7vw'
                xl='7vw'
            />
            <Doodle
                doodleName='leftstar'
                alt=''
                bottom={20}
                left={15}
                xs='5vw'
                sm='3vw'
                md='3vw'
                lg='3vw'
                xl='3vw'
            />
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Grid
                    container
                    direction='column'
                    alignItems={isSm ? 'center' : null}
                    rowSpacing={5}
                    pr={{
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 10,
                        xl: 10
                    }}
                >
                    <Grid xs item>
                        <Typography
                            color='#231E49'
                            variant='h1'
                            textAlign={isSm ? 'center' : 'right'}
                            sx={{
                                fontFamily: 'DreamAvenue',
                                fontSize: {
                                    xs: '12vw',
                                    sm: '12vw',
                                    md: '8vw',
                                    lg: '6vw',
                                    xl: '6vw'
                                }
                            }}
                        >
                            ¡CONTÁCTAME!
                        </Typography>
                    </Grid>
                    <Grid xs item>
                        <Typography
                            color='#231E49'
                            textAlign={isSm ? 'center' : 'right'}
                            px={{
                                xs: '3vw',
                                sm: '3vw',
                                md: '3vw',
                                lg: 0,
                                xl: 0
                            }}
                            pl={{
                                lg: '8vw',
                                xl: '8vw',
                            }}
                            sx={{
                                fontFamily: 'CampaignLight',
                                fontSize: {
                                    xs: '3vw',
                                    sm: '3vw',
                                    md: '2vw',
                                    lg: '1.5vw',
                                    xl: '1vw'
                                }
                            }}
                        >
                            Si te gusta mi forma de trabajar y tienes algun proyecto interesante en mente, ¡no dudes en escribirme!
                            <br />
                            Estoy como una lapa pegada al correo, así que trataré de responderte lo más pronto posible.
                            <br />
                            Si no, también puedes probar a hablarme a mis redes sociales, ahí también estoy atenta :)
                        </Typography>
                    </Grid>
                    <Grid xs item>
                        <SocialMediaButtons />
                    </Grid>
                </Grid>
            </Box >
        </Box>
    )
}

export const Contact = () => {

    return (
        <Grid
            container
            direction='row'
            height='100%'
            rowSpacing={5}
            py={{
                xs: 3,
                sm: 3,
                md: 5,
                lg: 15,
                xl: 17.5,
            }}
        >
            <Grid
                item
                xs={12} sm={12} md={12} lg xl
                order={{ xs: 2, md: 2, lg: 1, xl: 1 }}
            >
                <ContactForm />
            </Grid>
            <Grid
                item
                xs={12} sm={12} md={12} lg xl
                order={{ xs: 1, md: 1, lg: 2, xl: 2 }}
            >
                <ContactText />
            </Grid>
        </Grid>
    )
}