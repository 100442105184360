import Box from '@mui/material/Grid';

export const Doodle = ({
    absolute = true,
    doodleName,
    alt,
    top,
    left,
    bottom,
    right,
    xs = '10vw',
    sm = '10vw',
    md = '10vw',
    lg = '10vw',
    xl = '10vw'
}) => {
    const doodleFile = require(`../../assets/images/doodles/${doodleName}.svg`);

    const doodleComponent = (
        <Box
            component="img"
            src={doodleFile}
            srcSet={doodleFile}
            loading='lazy'
            alt={alt}
            color='#231E49'
            sx={{
                position: 'absolute',
                top: `${top}%`,
                left: `${left}%`,
                bottom: `${bottom}%`,
                right: `${right}%`,
                width: {
                    xs,
                    sm,
                    md,
                    lg,
                    xl
                }
            }}
        />
    );

    return (
        <>
            {absolute
                ? doodleComponent
                : (<Box
                    sx={{
                        position: 'relative',
                        height: '100%'
                    }}
                >
                    {doodleComponent}
                </Box>

                )}
        </>
    )
}