import { Grid, Box, Typography } from '@mui/material'
import { SocialMediaButtons } from './SocialMediaButtons';
import logoFooter from '../../assets/images/logo_footer.png';

export const Footer = () => {

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pb: 2.5
            }}
        >
            <Grid
                container
                direction='column'
                alignItems='center'
                spacing={1}
            >
                <Grid item>
                    <img
                        width='250vw'
                        src={logoFooter}
                        alt='Logo del pie de página'
                    />
                </Grid>
                <Grid item>
                    <Typography color='#231E49' variant='body1' textAlign='center' sx={{ fontFamily: 'CampaignLight', fontSize: 12 }}>
                        ¿Te gusta mi forma de trabajar?
                        <br />
                        ¡No dudes en contactarme!
                        <br />
                        <br />
                        celiaveiga7@gmail.com
                    </Typography>
                </Grid>
                <Grid item>
                    <SocialMediaButtons />
                </Grid>
            </Grid>
        </Box>
    )
}