import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Divider, Typography, IconButton, Modal, Fade, Backdrop, useMediaQuery } from '@mui/material';
import { HorizontalIconDivider } from './common/HorizontalIconDivider';
import projects from '../data/projects.json';
import { ArrowIcon } from './icons/ArrowIcon';
import { useTheme } from '@emotion/react';

const IMAGES_CONTEXT = require.context('../assets/images/projects');


const ProjectTitleDivider = ({ children }) => {
    return (
        <Grid
            container
            direction='row'
            justifyContent='center'
            alignContent='center'
            alignItems='center'
            columnSpacing={3}
        >
            <Grid item xs>
                <HorizontalIconDivider right={true} width='100%' />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                <Typography
                    noWrap={true}
                    variant='h3'
                    color='#231E49'
                    textAlign='center'
                    sx={{
                        fontFamily: 'DreamAvenue',
                        fontSize: {
                            xs: '8vw',
                            sm: '6vw',
                            md: '4vw',
                            lg: '3vw',
                            xl: '3vw'
                        }
                    }}
                >
                    {children}
                </Typography>
            </Grid>
            <Grid item xs>
                <HorizontalIconDivider left={true} width='100%' />
            </Grid>
        </Grid >
    )
}
const ProjectTechnicalDetails = ({ technicalDetails }) => {
    return (
        <Grid container direction='column'>
            <Grid item>
                <ProjectTitleDivider>
                    Ficha técnica
                </ProjectTitleDivider>
            </Grid>
            <Grid item xs>
                <Box
                    sx={{
                        py: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        px: '10vw'
                    }}
                >
                    {technicalDetails.map((td) =>
                        <Typography key={td.field} color='#231E49' textAlign='center' sx={{ lineHeight: 1.8 }}>
                            <Box component='span' fontWeight='bold'>{td.field}:</Box> <Box component='span' fontStyle='italic'>{td.values.join(', ')}</Box>
                        </Typography>
                    )}
                </Box>
            </Grid>
        </Grid>

    )
}

const ProjectSynopsis = ({ synopsis }) => {
    return (
        <Grid
            container
            direction='column'
            flexDirection='center'
            alignContent='center'
        >
            <Grid item>
                <ProjectTitleDivider>
                    Sinópsis
                </ProjectTitleDivider>
            </Grid>
            <Grid item xs>
                <Box
                    sx={{
                        pt: {
                            sm: 3,
                            md: 3,
                            lg: 5,
                            xl: 5
                        },
                        px: {
                            xs: '10vw',
                            sm: '15vw',
                            md: '15vw',
                            lg: '25vw',
                            xl: '30vw',
                        },
                    }}
                >
                    <Typography color='#231E49' sx={{ fontStyle: 'italic' }}>
                        {synopsis}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

const CarrouselVideo = ({ url }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                borderRadius: 5,
                overflow: 'hidden',
                zIndex: 1,
                height: {
                    xs: '20vh',
                    sm: '40vh',
                    md: '40vh',
                    lg: '50vh',
                    xl: '50vh'
                }
            }}
        >
            <iframe
                width='100%'
                height='100%'
                src={url}
                title='YouTube video player'
                frameborder='0'
                allow='fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                referrerpolicy='strict-origin-when-cross-origin'
                allowfullscreen
            >
            </iframe>
        </Box>
    )
}

const CarrouselImage = ({ imagePath, handleOpenModal, cinemaMode }) => {

    return (
        <Box
            display='flex'
            justifyContent='flex-end'
            alignItems='center'
            sx={{
                height: {
                    xs: '20vh',
                    sm: '40vh',
                    md: '40vh',
                    lg: '50vh',
                    xl: '50vh'
                }
            }}
        >
            <Box
                loading="eager"
                component="img"
                src={imagePath}
                alt="Project image"
                sx={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    height: 'auto',
                    borderRadius: 5,
                    margin: '0 auto'
                }}
            />
        </Box>
    )
}

const CarrouselElement = ({ element, handleOpenModal }) => {
    const isUrl = (element) => {
        try {
            new URL(element);
            return true;
        } catch (_) {
            return false;
        }
    }

    return (
        <>
            {
                isUrl(element)
                    ? <CarrouselVideo url={element} />
                    : <CarrouselImage imagePath={element} handleOpenModal={handleOpenModal} />
            }
        </>
    )
}

const CarrouselButton = ({ onClick, style }) => {
    return <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        alignContent='center'
    >
        <IconButton onClick={onClick}>
            <ArrowIcon style={style} color={style.color} fill={style.fill} />
        </IconButton>
    </Box >
}

const ModalCinemaMode = ({ cinemaMode, handleCloseModal, project }) => {
    return (
        <Modal
            open={cinemaMode}
            onClose={handleCloseModal}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Fade in={cinemaMode} timeout={500}>
                <Box>
                    <ProjectCarrousel project={project} />
                </Box>
            </Fade>
        </Modal >
    )
}

const CarrouselDisplay = ({ element, buttonStyle, decrease, increase, handleOpenModal = null, isFirst, isLast }) => {
    return (
        <Grid
            container
            direction='row'
            justifyContent='center'
            alignContent='center'
            spacing={1}
            px={1}
        >
            <Grid
                item
                xs={1}
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                {!isFirst() && <CarrouselButton onClick={decrease} style={{ ...buttonStyle, transform: `rotate(180deg)` }} />}
            </Grid>
            <Grid item xs={9} sm={10} md={8} lg={6} xl={6}>
                <CarrouselElement element={element} handleOpenModal={handleOpenModal} />
            </Grid>
            <Grid
                item
                xs={1}
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                {!isLast() && <CarrouselButton onClick={increase} style={buttonStyle} />}
            </Grid>
        </Grid >
    )
}

const ProjectCarrousel = ({ project, handleOpenModal }) => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const [carrousel, setCarrousel] = useState([]);
    const [element, setElement] = useState(null)

    useEffect(() => {
        const projectImages = IMAGES_CONTEXT.keys().filter(k => k.includes(project.id)).map(IMAGES_CONTEXT)

        setCarrousel([
            projectImages[0],
            ...project.videos,
            ...projectImages.slice(1),
        ])
    }, [project]);

    useEffect(() => {
        setElement(carrousel[0])
    }, [carrousel]);

    const increase = () => setElement(carrousel[carrousel.indexOf(element) + 1]);
    const decrease = () => setElement(carrousel[carrousel.indexOf(element) - 1]);
    const isFirst = () => carrousel.indexOf(element) === 0;
    const isLast = () => carrousel.indexOf(element) === carrousel.length - 1;

    const buttonSize = isSm ? '5vw' : '3vw';

    return (
        <>
            {element &&
                <CarrouselDisplay
                    element={element}
                    decrease={decrease}
                    increase={increase}
                    handleOpenModal={handleOpenModal}
                    isFirst={isFirst}
                    isLast={isLast}
                    buttonStyle={{
                        fontSize: buttonSize,
                        stroke: "#231E49",
                        color: '#231E49',
                        fill: '#231E49'
                    }}
                />
            }
        </>
    )
}

export const Project = () => {
    const { projectId } = useParams();
    const [project, setProject] = useState(null)
    const [cinemaMode, setCinemaMode] = useState(false);

    const handleOpenModal = () => setCinemaMode(true);
    const handleCloseModal = () => setCinemaMode(false);

    useEffect(() => {
        setProject(
            projects.find(p => p.id === projectId)
        )
    }, [projectId])

    return (
        <>
            {project &&
                <Grid
                    container
                    direction='column'
                    rowSpacing={5}
                    sx={{
                        py: {
                            xs: 2,
                            sm: 2,
                            md: 3,
                            lg: 5,
                            xl: 5
                        }
                    }}
                >
                    <Grid item>
                        <Typography
                            variant='h3'
                            textAlign='center'
                            color='#231E49'
                            sx={{
                                fontFamily: 'DreamAvenue',
                                fontSize: {
                                    xs: '9vw',
                                    sm: '6.5vw',
                                    md: '5vw',
                                    lg: '4vw',
                                    xl: '3.5vw'
                                }
                            }}
                        >
                            {project.title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ProjectCarrousel project={project} />
                        {
                            //<ModalCinemaMode cinemaMode={cinemaMode} handleCloseModal={handleCloseModal} project={project} />
                        }
                    </Grid>
                    <Grid item>
                        <ProjectSynopsis synopsis={project.synopsis} />
                    </Grid>
                    <Grid item>
                        <ProjectTechnicalDetails technicalDetails={project.technicalDetails} />
                    </Grid>
                </Grid >
            }
        </>
    )
}