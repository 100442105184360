
import { SvgIcon } from '@mui/material';

export const ArrowIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 57.41 66.3">
        <g id="arrowIcon" data-name="arrowIcon">
            <g id="carrusel">
                <polygon
                    fill={props.fill}
                    stroke={props.color}
                    strokeMiterlimit="10"
                    points=".66 1.14 5.02 33.15 .66 65.15 26.2 45.37 56.09 33.15 26.2 20.92 .66 1.14"
                />
            </g>
        </g>
    </SvgIcon>
);