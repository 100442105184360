import { List, Box, ListItemIcon } from "@mui/material";
import { Link } from "react-router-dom";
import { InstagramIcon } from "../icons/InstagramIcon";
import { LinkedInIcon } from "../icons/LinkedInIcon";
import { BehanceIcon } from "../icons/BehanceIcon";

export const SocialMediaButtons = () => {
    const socialMedia = [
        {
            key: "instagram",
            link: "https://www.instagram.com/celiaveigart/",
            icon: <InstagramIcon />
        },
        {
            key: "linkedin",
            link: "https://www.linkedin.com/in/celia-veiga-6021932b4/?trk=opento_sprofile_details",
            icon: <LinkedInIcon />
        },
        {
            key: "behance",
            link: "https://www.behance.net/celiaveigalpez",
            icon: <BehanceIcon />
        }
    ]

    return (
        <List
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                gap: 2
            }}
        >
            {socialMedia.map((item) => (
                <Link key={item.key} to={item.link} target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderWidth: 2,
                            borderStyle: "solid",
                            borderRadius: "50%",
                            borderColor: '#231E49',
                            p: 1,
                            width: 50,
                            height: 50,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                justifyContent: 'center',
                                color: "inherit"
                            }}
                        >
                            {item.icon}
                        </ListItemIcon>
                    </Box>
                </Link>
            ))}
        </List>
    )
}