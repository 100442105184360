import { SvgIcon } from '@mui/material';

export const MenuIcon = (props) => {
    const style = {
        fill: 'none',
        stroke: '#262451',
        strokeMiterlimit: 10,
        strokeWidth: '0.5px',
    }

    return (
        <SvgIcon {...props} viewBox="0 0 14 14.5">
            <g id="menu" data-name="menu">
                <g id="menu" data-name="menu">
                    <g>
                        <line
                            x1="0"
                            y1="0.25"
                            x2="14"
                            y2="0.25"
                            style={style}
                        />
                        <line
                            x1="0"
                            y1="7.25"
                            x2="14"
                            y2="7.25"
                            style={style}
                        />
                        <line
                            x1="0"
                            y1="14.25"
                            x2="14"
                            y2="14.25"
                            style={style}
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};