import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Grid';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';

const CursorCircle = () => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
    const cursorRef = useRef(null);
    const requestRef = useRef(null);
    const previousMousePosition = useRef({ x: 0, y: 0 });

    useEffect(() => {
        const updatePosition = () => {
            if (cursorRef.current) {
                const { x, y } = previousMousePosition.current;
                cursorRef.current.style.transform = `translate(${x}px, ${y}px)`;
            }
            requestRef.current = requestAnimationFrame(updatePosition);
        };

        const handleMouseMove = (event) => {
            previousMousePosition.current = { x: event.clientX - 15, y: event.clientY + 10 };
        };

        window.addEventListener('mousemove', handleMouseMove);
        requestRef.current = requestAnimationFrame(updatePosition);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            cancelAnimationFrame(requestRef.current);
        };
    }, []);

    return (
        <>
            {!isSm &&
                <Box
                    ref={cursorRef}
                    sx={{
                        position: 'fixed',
                        width: 30,
                        height: 30,
                        backgroundColor: '#F9D2DF',
                        borderRadius: '50%',
                        pointerEvents: 'none',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1000
                    }}
                />
            }
        </>
    );
};

export default CursorCircle;