
import { SvgIcon } from '@mui/material';

export const LinkedInIcon = (props) => {
    const style = {
        fill: '#231e49',
        fillRule: 'evenodd',
        strokeWidth: '0px',
    };

    return (
        <SvgIcon {...props} viewBox="0 0 13.01 13.07">
            <g id="linkedInIcon" data-name="linkedInIcon">
                <g id="contacto">
                    <path
                        d="M.26,4.4h2.58v8.67H.26V4.4ZM1.53,0c.86,0,1.56.7,1.56,1.56s-.7,1.56-1.56,1.56-1.53-.7-1.53-1.56S.67,0,1.53,0h0ZM4.37,4.4h2.55v1.21c.54-.8,1.43-1.31,2.45-1.31h.7c1.63,0,2.93,1.34,2.93,2.93v5.83h-2.55v-.8h0v-3.89h0c-.03-.99-.7-1.75-1.69-1.75h0c-.99,0-1.82.77-1.85,1.75h0v4.69h-2.55V4.4Z"
                        style={style}
                    />
                </g>
            </g>
        </SvgIcon>
    );
}