
import { SvgIcon } from '@mui/material';

export const InstagramIcon = (props) => {
    const style = {
        fill: '#231e49',
        fillRule: 'evenodd',
        strokeWidth: '0px',
    };

    return (
        <SvgIcon {...props} viewBox="0 0 13.91 13.87">
            <g id="instaIcon" data-name="instaIcon">
                <g id="contacto">
                    <path
                        d="M3.75,0h6.4c2.06,0,3.75,1.69,3.75,3.75v6.37c0,2.06-1.69,3.75-3.75,3.75H3.75c-2.06,0-3.75-1.69-3.75-3.75V3.75C0,1.69,1.69,0,3.75,0h0ZM10.69,2.42h0c.43,0,.8.37.8.8h0c0,.43-.37.8-.8.8h0c-.46,0-.8-.37-.8-.8h0c0-.43.33-.8.8-.8h0ZM6.94,3.15h.03c2.06,0,3.78,1.73,3.78,3.78h0c0,2.09-1.73,3.78-3.78,3.78h-.03c-2.06,0-3.75-1.69-3.75-3.78h0c0-2.06,1.69-3.78,3.75-3.78h0ZM6.94,4.45h.03c1.36,0,2.49,1.13,2.49,2.49h0c0,1.39-1.13,2.52-2.49,2.52h-.03c-1.36,0-2.49-1.13-2.49-2.52h0c0-1.36,1.13-2.49,2.49-2.49h0ZM3.78,1.19h6.34c1.43,0,2.59,1.16,2.59,2.59v6.31c0,1.43-1.16,2.59-2.59,2.59H3.78c-1.43,0-2.59-1.16-2.59-2.59V3.78c0-1.43,1.16-2.59,2.59-2.59h0Z"
                        style={style}
                    />
                </g>
            </g>
        </SvgIcon>
    );
}